.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -10px;
}

.anime,
.gaming,
.blogging {
  flex-basis: calc(33.33% - 20px);
  margin: 10px;
}

.anime,
.blogging,
.gaming {
  position: relative;
  width: 400px;
  height: 250px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.anime:hover,
.blogging:hover,
.gaming:hover {
  transform: scale(1.05);
}

.anime img,
.blogging img,
.gaming img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 20px;
  transform: translateY(100%);
  transition: all 0.3s ease;
}

.anime:hover .overlay,
.blogging:hover .overlay,
.gaming:hover .overlay {
  transform: translateY(0);
}
