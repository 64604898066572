#container {
  height: 350px;
  position: relative;
  overflow: hidden;
}

.photobanner {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  animation: bannermove 10s linear infinite;
}

.photobanner img {
  margin: 0 0.5em;
  transition: opacity 0.3s ease-in-out; /* Add transition for smooth effect */
}

.photobanner img:hover {
  opacity: 0.7; /* Change opacity on hover */
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
